/*
* Description: Supported Dimensions for Devices
*/
@mixin mobile-xxs {
    @media (max-width: 320px) {
        @content;
    }
}

@mixin mobile-xs {
    @media (max-width: 400px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin medium-tablet {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin medium-desktop {
    @media (max-width: 1152px) {
        @content;
    }
}

@mixin default-container {
    @media (max-width: 1100px) {
        @content;
    }
}
