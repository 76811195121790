/*
* Description: place for css variables (we need to use them to simplify theme customization)
*/

/* File Paths */
$image-path: "../../images" !default;

/* Colours */
$white-color: #ffffff !default;
$red-color: #ef3340;
$blue-color: #0033a0;
$black-color: #000000;
$primary-menu-bg: #1f1f1f;
$primary-blue: #022269;
$text-color-default: #434444;
$text-color-grey: #7c7b7b;
$published-time-color: #6f6f6f;
$tsn-branding-color: #cf0915;
$border-color-default: #e1e7ee;
$grey-color: #7d828e;
$aubergine-color: #221f20;
$footer-text-color: #5f5f5f;
$footer-bg: #f9f9f9;
$primary-menu-delimiter: #707070;
$secondary-menu-border: #d8d8d8;
$border-color-article: #e9ecf0;
$adv-placeholder-bg: #f7f7f7;
$secondary-menu-color: #231f20;
$sub-grey-color: #a8a8a8;
$nba-red-color: #ed263f;
$nba-blue-color: #1d428a;
$nba-subhead-blue-color: #2856af;
$nba-dropdown-title-color: #231f20;

/* Opta Colors */
$opta-match-home: #cccccc;
$opta-match-away: #efefef;

/* Enhance Affiliate Colors */
$affiliate-button-text-grey: #626676;
$affiliate-text-grey: #777777;
$affiliate-line-grey: #a7a6aa;
$affiliate-button-grey: #ebecf4;
$affiliate-red: #df1f2a;

/* Fonts */
$roboto-font: "Roboto", sans-serif;
$header-font: $roboto-font;

/* Font Sizes */

/* base font size - applied at body / html level */
$fs-base: 16px;

/* heading font sizes */
$fs-h1: 30px;
$fs-h6: 18px;

/* Line Height */
$lh-base: 28px;
