@use "../common" as *;

.widget-global-navigation {
    background-color: $white-color;
    position: relative;

    >.nbap-nav--global {
        height: 40px;
        display: -ms-flexbox;
        display: flex;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 10px;
    }

    @include tablet {
        width: 100%;
        z-index: 10001;
    }
}

.edition-picker {
    display: none;
    line-height: 45px;
    clear: both;
    overflow: hidden;
    background-color: #ffffff;
    color: #ffffff;

    &.message-open {
        display: block;
        opacity: 1;
        visibility: visible;
        transition: visibility linear 0.33s, opacity 0.33s linear;
        position: relative;
        z-index: 10002;
    }

    &.message-closed {
        display: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility linear 0.33s, opacity 0.33s linear;
    }

    #edition {
        position: fixed;
        z-index: 501;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #000000;
        background-color: #00000066;
        justify-content: center;
        align-items: center;

        &.block.message-open:not(.message-closed),
        &.block.message-open,
        &.message-open {
            display: flex !important;
            opacity: 1;
            visibility: visible;
            transition: visibility linear 0.33s, opacity 0.33s linear;
        }

        &.block:not(.message-closed),
        &.block.message-closed,
        &.message-closed {
            display: none !important;
            visibility: hidden;
            opacity: 0;
            transition: visibility linear 0.33s, opacity 0.33s linear;
        }
    }

    #edition_message {
        background-color: #1d428a;
        padding-left: 16px;
        box-sizing: border-box;
        height: 50px;
        display: flex;
        align-items: center;

        &.message-open {
            display: flex !important;
            opacity: 1;
            visibility: visible;
            transition: visibility linear 0.33s, opacity 0.33s linear;
        }

        &.message-closed {
            display: none !important;
            visibility: hidden;
            opacity: 0;
            transition: visibility linear 0.33s, opacity 0.33s linear;
        }
    }
}

.nbap-nav {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;

    @include tablet {
        width: unset;
    }
}

.nbap-nav__dropdown {
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-flow: column wrap;
    height: 100%;

    &:last-child {
        padding-bottom: 0;
    }

    >* {
        padding-bottom: 20px;
    }
}

.page-branded-nba {
    .nbap-nav__menu {
        font-weight: 700;
        text-align: left;
    }
}

.nbap-nav__menu {
    overflow: auto;
    list-style-type: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -ms-flex-align: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;

    &.nbap-nav--global__menu {
        overflow: hidden;
        padding: 0;

        @include tablet {
            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }
        }
    }
}

.nbap-nav__menu-item {
    margin: 0 20px 0 0;

    &:last-of-type {
        margin-right: 0;
    }
}

.nbap-nav__link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1f1f1f;
    font-family: $roboto-font;
    font-size: rem(12px);
    font-weight: bold;
    text-decoration: none;
    padding: 0;
    line-height: 40px;
    cursor: pointer;

    @include mobile {
        font-size: rem(10px);
    }
}

.nbap-nav--global {
    .nbap-nav__dropdown-container {
        top: 40px;
    }
}

.nbap-nav__dropdown-container {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-1%);
    opacity: 0;

    >ul.nbap-nav__dropdown {
        display: flex;
        background-color: #f9f9f9;
        border-top: 1px solid #d8d8d8;
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-bottom: 2px solid #ef3340;
        padding: 37px 27px 32px;
        box-sizing: border-box;
        width: fit-content;
        min-width: 330px;
        list-style-type: disc;
        columns: 2;
        list-style-position: inside;
    }
}

.nbap-nav__dropdown-item {
    position: relative;
    min-width: 125px;
    padding-left: 10px;
    list-style: none;
}

.nbap-nav__dropdown-link {
    font-size: 14px;
    font-weight: bold;
    color: #231f20;
    text-decoration: none;
    padding-left: 20px;
    margin-left: -10px;
    line-height: initial;
    transition: color 0.3s linear;
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-position: left center;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='rgba(239, 51, 64, 1)' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'  viewBox='0 0 256 256' style='enable-background:new 0 0 256 256;' xml:space='preserve'%3E  %3Cpolygon points='79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128 		'/%3E  %3C/svg%3E  ");

    &:hover {
        color: #ef3340;
    }

    &:after {
        display: none;
    }
}

.nbap-nav__global,
.nbap-nav__team {
    z-index: 10001;
    height: fit-content;
    width: fit-content;
    pointer-events: none;
}

#setDeafultEditionForm {
    position: relative;
    background-color: #1d428a;
    margin: auto;
    padding: 20px;
    width: 262px;
    height: 177px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #0433a0;
    box-sizing: border-box;

    >.close-button {
        background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>');
        background-size: 20px;
        display: block;
        filter: invert(1);
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}

.choose-edition {
    font-family: $roboto-font;
    font-size: 16px;
    font-weight: bold;
    pointer-events: none;
}

.controls {
    font-family: $roboto-font;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;

    input[type="radio"] {
        cursor: pointer;
        margin-left: 5px;

        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }
}

.edition-message {
    font-size: 12px;
    font-weight: bold;
    font-family: $roboto-font;
}

@media screen and (max-width: 768px) {
    .widget-global-navigation {
        >.nbap-nav--global {
            justify-content: center;
        }
    }

    .nbap-nav__dropdown-container {
        width: 100%;
        left: 0;
        position: absolute;
        right: 0;
        margin: auto;

        >ul.nbap-nav__dropdown {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            overflow: auto;
            max-height: calc(100vh - 90px);
            height: fit-content;
        }
    }

    .nbap-nav__link[title="Teams"] {
        + {
            .nbap-nav__dropdown-container {
                >.nbap-nav__dropdown {
                    &:before {
                        left: 36%;
                    }

                    &:after {
                        left: 36%;
                    }
                }
            }
        }
    }

    .nbap-nav__link[title="Global"] {
        + {
            .nbap-nav__dropdown-container {
                >.nbap-nav__dropdown {
                    &:before {
                        left: 50%;
                    }

                    &:after {
                        left: 50%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 98.75em) {
    .page-branded-nba {
        .nbap-nav__menu {
            width: 77.25rem;
            margin: 0 auto;
        }
    }
}

@media screen and (min-width: 61.25em) {
    .page-branded-nba {
        .nbap-nav__menu {
            -ms-flex-pack: start;
            justify-content: flex-start;
            width: 60.75rem;
            margin: 0 auto;
        }
    }
}

@media screen and (min-width: 769px) {
    .nbap-nav__dropdown-container.nbap-nav__team {
        >ul.nbap-nav__dropdown {
            height: 660px;
        }
    }

    .nbap-nav__dropdown-container.nbap-nav__global {
        >ul.nbap-nav__dropdown {
            height: 510px;
        }
    }

    .nbap-nav__dropdown-container {
        >ul.nbap-nav__dropdown {
            >li {
                min-width: 140px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .nbap-nav__dropdown-container {
        >ul.nbap-nav__dropdown {
            display: grid;
            grid-template-columns: 1fr 1fr;
            overflow: auto;
            max-height: calc(100vh - 90px);
            height: fit-content;
            width: 100%;
        }
    }

    .nbap-nav__team {
        width: 100%;
    }

    .nbap-nav__global {
        width: 100%;
    }
}

.nbap-nav--global__menu-item {
    &:has(a[title="Global"]):hover,
    &:has(a[title="Teams"]):hover {
        >a {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                transform: translate(-50%, 100%);
                border-width: 0 14px 14px;
                border-color: transparent transparent #f9f9f9;
                z-index: 23;
            }

            &:before {
                border-width: 0 14px 13px;
                border-color: transparent transparent #d8d8d8;
                z-index: 22;
            }
        }

        .nbap-nav__global,
        .nbap-nav__team {
            pointer-events: visible;
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.nba-title-wrap {
    display: none;

    @include mobile {
        display: block;
        font-size: 1rem;
        position: relative;
        margin: 0 15px;
    }
}

@include tablet {
    .nba-scoreboard-container.overlay {
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(34 31 32 / 0.72);
            z-index: 10;
        }
    }
}

.Layout_bg__pX_1K {
    background-color: transparent !important;
}

.Footer_footerRow__JMa1h {
    background-color: #f6f6f6 !important;
}
